import Vue from 'vue';
import Router from 'vue-router';
//import store from './../store';

import Main from '@/pages/Main.vue';
import Login from '@/pages/Login.vue';
import Register from '@/pages/Register.vue';
import Blank from '@/pages/Blank.vue';
import Dashboard from '@/pages/Dashboard/Dashboard.vue';
import Profile from '@/pages/Profile.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Main,
      beforeEnter(to, from, next) {
       // if(false) {
       // if (store.state.token) {
          next();
      //  } else {
          next();
      //  }
      },
      children: [
        { path: '/blank', component: Blank },
        { path: 'profile', component: Profile },
        { path: '/', component: Dashboard }
      ]
    },
    {
      path: '/login',
      component: Login,
      beforeEnter(to, from, next) {
        next();
        // if (false) {
        // //if (store.state.token) {
        //   next('/');
        // } else {
          
        // }
      }
    },
    {
      path: '/register',
      component: Register,
      beforeEnter(to, from, next) {
        // if (store.state.token) {
          next();
        // if(false) {
        //   next('/');
        // } else {
        //   next();
        // }
      }
    }
  ]
});
