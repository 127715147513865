import axios from '../utils/axios';

export default {
  getDashboardData(filters: any) {
    console.log("filters => ", filters);
    return axios.get('/dashboard/workflows', { params: {...filters} });
  },
  getInstancesData(filters: any) {
    return axios.get('/dashboard/instances', { params: {...filters} });
  }
};