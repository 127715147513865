<template>
  <router-view></router-view>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: "app",
  components: {}
});
</script>
<style>
@import url("https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css");
/* @import url("../node_modules/bootstrap/dist/css/bootstrap.min.css"); */
@import url("https://adminlte.io/themes/v3/dist/css/adminlte.min.css");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700");
@import "./assets/css/style.css";
</style>
