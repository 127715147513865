













































































































































































































































import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import bot from "../../controllers/bot.controller";
import momentt from 'moment';


interface Workflows {
  elegiveis?: number;
  nao_elegiveis?: number;
  analisadas?: number;
  workflows?: Array<{title?: string,  elegiveis?: number, nao_elegiveis?: number, analisados?: number }>
}

interface Filtros {
        data_inicio: string;
        data_fim: string;
        elegiveis?: boolean;
        pagina: number;
        limite: number;
        guia: any;
}

interface Instances {
      created_at:  string;
      description: string;
      full_count: number;
      guia_id: number;
      selected: boolean;
}


interface BaseData {
  workflows?: Workflows;
  filtros: Filtros;
  total: number;
  instances?: Instances[];
  loading: boolean;
  workflows_?: [];
  data?: { pagina: number, total: number, data: Instances[] }
}


import Vue from 'vue';

export default Vue.extend({
  
  components: {DatePicker},

  data () {
    return { 
      baseData: {
        filtros: {
          data_inicio: momentt().format('YYYY-MM-01'),
          data_fim: momentt().format('YYYY-MM-DD'),
          elegiveis: true,
          workflows_id: [],
          pagina: 1,
          limite: 10,
          guia: ''
        },
        total: 0,
        data: {
            pagina: 0 ,
            total: 0, 
            data: []
        },
        loading: false,
        instances: [],
        workflows: [],
        workflows_: []
      } as BaseData
    };
  },

  created(): void {
    
  },

  mounted() {
      this.getWorkflows();
      this.getInstances();
  },

  methods: {
    checknum: function () {
            let g: string = this.baseData.filtros.guia; 
            this.baseData.filtros.guia = g.replace(/[^0-9]/g, '');
    },
    loadRelaodData: function() {
      if( !this.isExcedPages || this.currentPage == 1 ) {
        this.getInstances();
      }
      this.getWorkflows();
    },
    getWorkflows: function () {
      this.baseData.loading = true;
      
      bot.getDashboardData(this.baseData.filtros)
        .then( data => data.data )
        .then( (data: Workflows) => {
          this.baseData.loading = false;
          this.baseData.workflows = {...this.baseData.workflows, ...data};          
      })
    },

    getInstances: function (): void {
      this.baseData.loading = true;
          let filters = this.computedFilter;
          bot.getInstancesData(filters)
          .then( data => data.data )
          .then( (data: BaseData) => {
            this.baseData.loading = false;
            this.baseData = {...this.baseData, ...data};
          });
      
      
    },
    nextPage() {
      this.baseData.filtros.pagina++;
      this.getInstances();
    },
    previousPage() {
      this.baseData.filtros.pagina--;
      this.getInstances();
    },
    changePage(offset: number) {        

        console.log("offset => ", offset);

    }
  },
  computed: {    
    computedFilter(): object {
    
        if(this.isFilterWithGuia) {
            return { limit: this.baseData.filtros.limite,  guia: this.baseData.filtros.guia};
        }
 
        return this.baseData.filtros;

    },
    isFilterWithGuia(): boolean {
      return (this.baseData.filtros.guia != '');
    },
    isExcedPages() : boolean {
      return this.baseData.filtros.pagina > this.pages;
    },
    currentPage() : number {
      return this.baseData.filtros.pagina;
    },
    pages() : number {
          return Math.ceil(this.baseData.total / this.baseData.filtros.limite);
    },
    showPrevious() : boolean {
          return this.baseData.total > 0 && this.baseData.filtros.pagina > 1;
    },
    showNext() : boolean {
          return this.baseData.filtros.pagina < this.pages;
    }
  }

});

