












































































import Vue from 'vue';

export default Vue.extend({
  name: "Register",
  data: function() {
    return {};
  },
  methods: {
    register: function() {
      this.$router.push({ path: "/" });
    }
  },
  created: function() {
    document.body.classList.add("register-page");
  },
  destroyed: function() {
    document.body.classList.remove("register-page");
  }
});
