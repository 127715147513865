
















import Vue from 'vue';
import Header from "@/components/main/Header.vue";
import SidebarMenu from "@/components/main/SidebarMenu.vue";
import Footer from "@/components/main/Footer.vue";

function resizeWindow() {
  console.log("window.innerHeight", window.innerHeight);
  console.log("window.innerWidth", window.innerWidth);
}

export default Vue.extend({
  name: "Main",
  components: {
    Header,
    SidebarMenu,
    Footer
  },
  methods: {
    toggleMenuSidebar: function() {
      if (document.body.classList.contains("sidebar-open")) {
        document.body.classList.remove("sidebar-open");
        document.body.classList.add("sidebar-collapse");
      } else if (document.body.classList.contains("sidebar-collapse")) {
        document.body.classList.remove("sidebar-collapse");
        document.body.classList.add("sidebar-open");
      }
    }
  },
  created: function() {
    window.addEventListener("resize", resizeWindow);
  },
  destroyed: function() {
    window.removeEventListener("resize", resizeWindow, false);
  }
});
