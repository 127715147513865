<template>
  <footer class="main-footer">
    <strong>
      Copyright &copy; 
      <a href="https://livnow.livsaude.com.br/">LIV Saúde</a>.
    </strong>
    All rights reserved.
    <div class="float-right d-none d-sm-inline-block">
      <b>LIVBot</b> 0.0.1
    </div>
  </footer>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: "Footer"
});
</script>

<style scoped>
</style>