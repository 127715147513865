







interface Workflows {
  elegiveis: Number;
  nao_elegiveis: Number;
  analisadas: Number;
}

interface BaseData {
  workflows?: Workflows;
  total: number;
  instances?: [];
  loading: boolean;
  workflows_?: [];
}

import { Component, Vue } from 'vue-property-decorator'
@Component({
  name: 'Blank'
})
export default class Blank extends Vue {

  private name: string = 'John Esrom';
  private baseData: BaseData = {
    workflows: {elegiveis: 3, nao_elegiveis: 6, analisadas: 12},
    total: 0,
    loading: false
  };

  mounted() {
    console.log('montado');
  }

}
