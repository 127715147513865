<template>
  <aside class="main-sidebar elevation-1 sidebar-light-primary">
    <!-- Brand Logo -->
    <router-link to="/" class="brand-link">
      <span class="brand-text font-weight-light"><b>LIVBOT</b></span>
    </router-link>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img
            src="./../../assets/img/default-user.png"
            class="img-circle elevation-2"
            alt="User Image"
          />
        </div>
        <div class="info">
          <router-link to="/profile" class="d-block">John Esrom</router-link>
        </div>
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <!-- Add icons to the links using the .nav-icon class
          with font-awesome or any other icon font library-->

          <li class="nav-item has-treeview">
            <router-link to="/" class="nav-link nav-link active">
              <i class="nav-icon fas fa-th"></i>
              <p>Dashboard</p>
            </router-link>
          </li>
          <!-- <li class="nav-item has-treeview">
            <router-link to="/profile" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>Profile</p>
            </router-link>
          </li>
          <li class="nav-item has-treeview">
            <router-link to="/blank" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>Blank Page</p>
            </router-link>
          </li> -->
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: "SidebarMenu",
  data: function() {
    return {
      isMenuOpen: false
    };
  },
  computed: {
    authUser() {
      return this.$store.getters.getAuthUser;
    }
  },
  methods: {
    toogleMenu: function(event) {
      event.stopPropagation();
      event.preventDefault();
      this.isMenuOpen = !this.isMenuOpen;
    }
  }
});
</script>

<style>
</style>